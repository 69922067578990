import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layouts"
import styled from "styled-components"
import ClassSchedule from "../components/ClassSchedule"
import SEO from "../components/SEO"
const BottomSpacer = styled.div`
  margin-bottom: 400px;
  @media (max-width: 500px) {
    margin-bottom: 500px;
  }
  @media (max-width: 400px) {
    margin-bottom: 600px;
  }
`
const Header = styled.div`
  text-align: center;
  margin: 0 7%;
`
const ContactLink = styled(Link)`
  
  text-decoration: none;
  font-weight: bold;
`
const SpecialAnnouncments = styled.div`
  width: 60%;
  margin: 50px auto;
  text-align: center;
  
`
const Announcment = styled.div`
  padding: 20px;
`
const SpecialTitle = styled.h3`
  font-weight: bold;
  padding: 3px;
`
const SpecialDescription = styled.h4``

const TopDescription = styled.p`
  font-size: large;
`
const Seminar = styled(Link)`

  text-decoration: none;
  font-weight: bold;
`
const Schedule = () => {
  const { allSanityAnnouncments } = useStaticQuery(graphql`
    query {
      allSanityAnnouncments {
        nodes {
          _createdAt(formatString: "dddd MMMM Do, YYYY")
          description
          title
        }
      }
    }
  `)

  
  return (
    <Layout>
      <SEO title="Class Schedule" />

      <Header>
        <h2>Class Schedule</h2>

        <TopDescription>
          The School of the Natural Order offers classes online. The classes are
          taught by students for students. If you are interested in joining a
          class, please fill out the{" "}
          <ContactLink to="/contact">contact</ContactLink> form and indicate
          which class you are interested in.
        </TopDescription>
        {/* <Seminar to ="/seminar">Click here for the 2021 Summer Seminar Schedule!</Seminar> */}
      </Header>
      <SpecialAnnouncments>
        
        {allSanityAnnouncments.nodes.map(e => (
          <Announcment>
            <SpecialTitle>{e.title}</SpecialTitle>
            <SpecialDescription>{e.description}</SpecialDescription>
          </Announcment>
        ))}
      </SpecialAnnouncments>
      <ClassSchedule />

      

      <BottomSpacer />
    </Layout>
  )
}
export default Schedule
