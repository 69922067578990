import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 1000px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 50px auto;
`

const ClassList = styled.div`
  text-align: start;
  padding: 20px;
  border: 1px solid black;
  margin: 10px 0;
`

const Title = styled.h4`
  
`
const Description = styled.p`
  font-style: italic;
  font-size: 16px;
  text-align: center;
`
const Date = styled.h5`
  padding: 14px;
`
const Author = styled.h4`
  
  font-style: italic;
  
`

export default function ClassSchedule({ classSchedule }) {
  const { schedule } = useStaticQuery(graphql`
    query {
      schedule: allSanitySchedule(sort: {fields: _createdAt, order: ASC}) {
        nodes {
          title
          description
          startDate(formatString: "dddd MMMM Do, YYYY")
          author
        }
      }
    }
  `)
 console.log(schedule)
  return (
    <Wrapper>
      {schedule.nodes.map(node => {
        
        const date = 
           (<Date>
              Began on, {node.startDate.split(" ").slice(-3).join(" ")}.
            </Date>)
        return(
        
        <ClassList key={`key-${node.title}`}>
          <Title>
            <strong>{node.title},</strong> by {node.author}.
          </Title>
          <Author></Author>
          {date}
          <Description>{node.description}</Description>
        </ClassList>
      )}
      )}
    </Wrapper>
  )
}
